/**
 * Copyright ©  Unified Arts GmbH All rights reserved.
 * See LICENSE.txt for license details.
 *
 * {@link https://www.3step-checkout.de/}
 */

/* eslint-disable max-len */
define('UnifiedArts_TscAugment/js/lib/knockout/template/inject-data-mixin',[
    'UnifiedArts_Tsc/js/active',
    'UnifiedArts_TscAugment/js/validate-email-active'
], function (tscActive, tscValidateEmailActive) {
    'use strict';

    return function (target) {
        if (!tscActive.isActive()) {
            return target;
        }

        if (tscValidateEmailActive.isActive()) {
            target.replace.push([
                'augment-1-vendor/magento/module-checkout/view/frontend/web/template/form/element/email.html',
                'data-role="email-with-possible-login"',
                'submit:login',
                'submit:login' + ', afterRender: tscValidateEmail'
            ]);

            target.replace.push([
                'augment-2-vendor/magento/module-checkout/view/frontend/web/template/form/element/email.html',
                'data-role="email-with-possible-login"',
                '\'validate-email\':true',
                '\'validate-email\':true' + ', \'tsc-email-always-invalid\': true'
            ]);
        }

        return target;
    };
});
/* eslint-enable max-len */
;
