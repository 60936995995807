/**
 * Copyright ©  Unified Arts GmbH All rights reserved.
 * See LICENSE.txt for license details.
 *
 * {@link https://www.3step-checkout.de/}
 */

define('UnifiedArts_Tsc/js/lib/knockout/template/renderer-mixin',[
    'jquery',
    'underscore',
    'UnifiedArts_Tsc/js/active',
    'UnifiedArts_Tsc/js/lib/knockout/template/inject-content'
], function ($, _, tscActive, tscInjectContent) {
    'use strict';

    return function (renderer) {
        var originalParseTemplate;

        if (!(tscActive.isActive() && tscActive.isCheckoutPage())) {
            return renderer;
        }

        originalParseTemplate = renderer.parseTemplate;

        /**
         * @inheritdoc
         * @see vendor/magento/module-ui/view/base/web/js/lib/knockout/template/loader.js loadFromFile()
         * @override
         */
        renderer.parseTemplate = function (html) {
            html = tscInjectContent.injectContent(html);

            return originalParseTemplate(html);
        };

        return renderer;
    };
});

