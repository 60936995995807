/**
 * Copyright ©  Unified Arts GmbH All rights reserved.
 * See LICENSE.txt for license details.
 *
 * {@link https://www.3step-checkout.de/}
 */

define('UnifiedArts_Tsc/js/active',[], function () {
    'use strict';

    return {
        isCheckoutConfig: !!window.checkoutConfig,
        active: !!(window.checkoutConfig && window.checkoutConfig.tsc && window.checkoutConfig.tsc.active),
        isCheckoutOnepage: !!(window.checkoutConfig &&
            window.checkoutConfig.tsc &&
            window.checkoutConfig.tsc.isCheckoutOnepage
        ),
        isInCartPage: !!(window.checkoutConfig && window.checkoutConfig.tsc && window.checkoutConfig.tsc.isCartPage),

        /**
         * @return {Boolean}
         */
        isActive: function () {
            return this.isCheckoutConfig && this.active;
        },

        /**
         * @return {Boolean}
         */
        isCheckoutPage: function () {
            return this.isCheckoutConfig && this.isCheckoutOnepage;
        },

        /**
         * @return {Boolean}
         */
        isCartPage: function () {
            return this.isCheckoutConfig && this.isInCartPage;
        }
    };
});

