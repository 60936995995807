define('Magento_Theme/js/theme',[
    'jquery',
    'matchMedia',
    'stickybits'
], function ($, mediaCheck, stickybits) {
    'use strict';

    setTimeout(function () {
        stickybits('.product-info-main-inner', { stickyBitStickyOffset: 213 });
    }, 100);

    mediaCheck({
        media: '(max-width: 768px)',
        entry: function () {
            $('.row-footer h6').not('.el-title').on('click', function () {
                $(this).closest('.el').toggleClass('active-list');
            });

            // always have search open on start, but keep toggle
            $('.header-mm-container').addClass('active-search');

            $('.mm-search-icon').on('click', function () {
                $('.header-mm-container').toggleClass('active-search');
            });
        },
        exit: function () {
            $('.row-footer h6').not('.el-title').prop('onclick', null).off('click');
            $('.mm-search-icon').prop('onclick', null).off('click');
        }
    });

    $('.dropdown-toggle').hover(function () {
        $('.dropdown-menu', this).trigger('click');
    });
});



