/**
 * Copyright ©  Unified Arts GmbH All rights reserved.
 * See LICENSE.txt for license details.
 *
 * {@link https://www.3step-checkout.de/}
 */

define('UnifiedArts_Tsc/js/lib/knockout/template/inject-content',[
    'underscore',
    'UnifiedArts_Tsc/js/lib/knockout/template/inject-data'
], function (_, tscInjectData) {
    'use strict';

    var injectData = tscInjectData.replace;

    return {
        /**
         * Inject html in templates
         * Inject html in ko templates instead of replacing default templates entirely.
         * @param {String} html
         * @returns {String}
         */
        injectContent: function (html) {
            _.each(injectData, function (data) {
                if (html.indexOf(data[1]) !== -1) {
                    html = html.replace(data[2], data[3]);
                }
            });

            return html;
        }
    };
});

