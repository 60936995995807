define('Magento_Theme/js/add-elements-on-mobile',[
    'jquery',
    'dropdownDialog'
], function ($, dropdownDialog) {
    'use strict';

    function extendMediaCheck(
        minSize = null,
        maxSize = null,
        minResolution = null,
        maxResolution = null,
        paramEntry = null,
        paramExit = null
    ) {
        mediaCheck({
            media: (
                minSize !== null && minResolution !== null ? '(' + minSize + ': ' + minResolution + ')' : '',
                    minSize !== null && maxSize !== null ? ' and ' : '',
                    maxSize !== null && maxResolution !== null ? '(' + maxSize + ': ' + maxResolution + ')' : ''
            ),
            entry: typeof paramEntry === 'function' ? paramEntry : function () {paramEntry},
            exit: typeof paramExit === 'function' ? paramExit : function () {paramExit}
        });
    }

    function initDropdownDialog (triggerElm, contentElm) {
        $(contentElm).dropdownDialog({
            "appendTo": "[data-ui-id=language-switcher]",
            "triggerTarget": $(triggerElm),
            "timeout": "100",
            "closeOnMouseLeave": false,
            "closeOnEscape": true,
            "triggerClass": "active",
            "parentClass": "active",
            "buttons": []
        });
    }

    function moveElMenu(el, destination) {
        $(el).detach().appendTo(destination)
    }

    return function addElementsOnMobile(triggerElm, contentElm, el, destination) {
        let dropdownDialog = initDropdownDialog(triggerElm, contentElm),
            switcherLanguage = moveElMenu(el, destination);

        extendMediaCheck(null, 'max-width', null, '768px', null, dropdownDialog);
        extendMediaCheck(null, 'max-width', null, '768px', null, moveElMenu);
    }
});

