define('WeltPixel_CustomHeader/js/slick/main',[
    'jquery',
    'uiComponent',
    'matchMedia',
    'js/vendor/slick/slick',
    'domReady!',
], function ($, Component, mediaCheck, slick) {
    'use strict';

    return Component.extend({
        defaults: {
            dropdownMenu: '.global-notification-wrapper .dropdown-menu',
            accordion: '.accordion-mm',
            elSlider: '.pre-header',
            settings: {
                dots: false,
                infinite: false,
                autoplay: false,
                arrows : true,
                slidesToShow: 1,
                slidesToScroll: 1
            },
        },

        initialize: function() {
            this._super();
            var self = this;

            self._initSlider();

            $(document).on('click', self.dropdownMenu, function (e) {
                e.stopPropagation();
            });

            $(window).scroll(function() {
                clearTimeout($.data(this, 'scrollTimer'));
                $.data(this, 'scrollTimer', setTimeout(function() {
                    // do something
                    if($('.dropdown-menu').is(':visible')) {
                        $('.global-notification-wrapper').click();
                    }
                }, 250));
            });

            self._initAccordion();
        },

        _initAccordion: function () {
            var self = this;

            mediaCheck({
                media: '(max-width: 640px)',
                entry: function () {
                    $('.accordion-mm td').hide()
                    $(self.accordion).click(function() {
                        if($(this).hasClass('active')) {
                            $(this).find('td').slideUp().parent().removeClass('active');
                            return;
                        }
                        $('.accordion-mm.active').find('td').slideUp().parent().removeClass('active');
                        $(this).find('td').slideDown({
                            start: function () {
                                $(this).css({
                                    display: "flex"
                                })
                            }
                        }).parent().addClass('active');
                    });
                },
                exit: function () {
                    $('.accordion-mm td').css("display", "table-cell");
                    $(self.accordion).prop('onclick', null).off('click');
                }
            });
        },

        _initSlider: function () {
            var self = this,
                globalPromo = $('.page-wrapper .page-header').find('.header-global-promo');

            mediaCheck({
                media: '(max-width: 1024px)',
                entry: function () {
                    if (!$(self.elSlider).hasClass('slick-initialized')) {
                        $(self.elSlider).slick(self.settings);

                        $(self.elSlider).on('setPosition', function () {
                            if (globalPromo.is(':visible')) {
                                globalPromo.css('visibility', 'visible');
                            }
                        });

                        $('.header-global-promo').on('showComplete', function () {
                            $(self.elSlider).slick('setPosition');
                        });
                    }
                },
                exit: function () {
                    if ($(self.elSlider).hasClass('slick-initialized')) {
                        $(self.elSlider).slick('unslick');
                    }
                }
            });
        }
    });
});

