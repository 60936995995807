/**
 * Copyright ©  Unified Arts GmbH All rights reserved.
 * See LICENSE.txt for license details.
 *
 * {@link https://www.3step-checkout.de/}
 */

define('UnifiedArts_Tsc/js/view/checkout/loginCaptcha-mixin',[
    'ko',
    'underscore',
    'uiElement',
    'UnifiedArts_Tsc/js/active'
], function (
    ko,
    _,
    uiElement,
    tscActive
) {
    'use strict';

    var mixin = {
        defaults: {
            tscIsFloated: false,
            autocomplete: false
        },

        /**
         * @param {Object} data
         * @param {Event} event
         */
        tscOnFocus: function (data, event) {
            var target = event.target;

            target.parentNode.parentNode.classList.add('_isFloated');
            this.tscIsFloated = true;
        },

        /**
         * @param {Object} data
         * @param {Event} event
         */
        tscOnBlur: function (data, event) {
            var target = event.target;

            if (!(!_.isUndefined(target.value) && !_.isEmpty(target.value))) {
                target.parentNode.parentNode.classList.remove('_isFloated');
                this.tscIsFloated = false;
            }
        },

        /**
         * @param {Object} element
         */
        tscAfterRender: function (element) {
            // Init control with a class
            element.parentElement.className += ' init';

            // Check if there is already a value stored
            if (!_.isUndefined(element.value) && !_.isEmpty(element.value)) {
                element.parentElement.parentElement.className += ' _isFloated';
                this.tscIsFloated = true;
            }
        },

        /**
         * @inheritdoc
         * @override
         */
        onUpdate: function () {
            var self = this,
                ret = self._super(),
                value = self.value(),
                isEmpty = !(!_.isUndefined(value) && !_.isEmpty(value)),
                el;

            if (!isEmpty && !self.tscIsFloated) {
                el = $('#' + self.uid);

                if (el.length > 0) {
                    el.get(0).parentElement.parentElement.className += ' _isFloated';
                    self.tscIsFloated = true;
                }
            } else if (isEmpty && self.tscIsFloated) {
                el = $('#' + self.uid);

                if (el.length > 0) {
                    el.get(0).parentNode.parentNode.classList.remove('_isFloated');
                    self.tscIsFloated = false;
                }
            }

            return ret;
        }
    };

    return function (target) {
        if (!(tscActive.isActive() && tscActive.isCheckoutPage())) {
            return target;
        }

        return target.extend(mixin);
    };
});

