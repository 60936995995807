/**
 * Copyright ©  Unified Arts GmbH All rights reserved.
 * See LICENSE.txt for license details.
 *
 * {@link https://www.3step-checkout.de/}
 */

define('UnifiedArts_Tsc/js/collapsible-mixin',[
    'ko',
    'jquery',
    'UnifiedArts_Tsc/js/active',
    'mage/translate',
    'jquery-ui-modules/widget'
], function (ko, $, tscActive, $t) {
    'use strict';

    return function (target) {
        var openText = '',
            closeText = '';

        if (!tscActive.isActive()) {
            return $.mage.collapsible;
        }

        openText = $t('Add');
        closeText = $t('Close discount');

        $.widget('mage.collapsible', target, {
            /** @inheritdoc */
            _create: function () {
                this._nullifyCollapsible();
                this._super();
            },

            /**
             * @inheritdoc
             * @override
             */
            _processPanels: function () {
                this._nullifyCollapsible();
                this._super();
            },

            /**
             * Force no collapsible
             * @private
             */
            _nullifyCollapsible: function () {
                var force = false;

                // Determine if checkout onepage
                if (!$('body').hasClass('checkout-index-index')) {
                    return;
                }

                // Summary cart items
                if (this.element.hasClass('items-in-cart')) {
                    force = true;
                }

                if (this.element.hasClass('product') &&
                    this.element.hasClass('options') &&
                    this.element.parents().hasClass('items-in-cart')
                ) {
                    force = true;
                }

                if (force) {
                    this.options.active = true;
                    this.options.collapsible = false;
                }
            },

            /** @inheritdoc */
            _open: function () {
                this._super();

                if (this.element.hasClass('discount-code')) {
                    this.element.find('.action-toggle span').text(closeText);
                }
            },

            /** @inheritdoc */
            _close: function () {
                this._super();

                if (this.element.hasClass('discount-code')) {
                    this.element.find('.action-toggle span').text(openText);
                }
            }
        });

        return $.mage.collapsible;
    };
});

