/**
 * Copyright ©  Unified Arts GmbH All rights reserved.
 * See LICENSE.txt for license details.
 *
 * {@link https://www.3step-checkout.de/}
 */

define('UnifiedArts_TscAugment/js/validate-email-active',[
    'UnifiedArts_Tsc/js/active'
], function (tscActive) {
    'use strict';

    return {
        active: window.checkoutConfig && window.checkoutConfig.tscAugment && window.checkoutConfig.tscAugment.email ?
            window.checkoutConfig.tscAugment.email.emailValidation : false,

        /**
         * @returns {Boolean}
         */
        isActive: function () {
            return tscActive.isActive() && tscActive.isCheckoutPage() && this.active;
        }
    };
});

