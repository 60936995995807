/**
 * Copyright ©  Unified Arts GmbH All rights reserved.
 * See LICENSE.txt for license details.
 *
 * {@link https://www.3step-checkout.de/}
 */

define('UnifiedArts_Tsc/js/visibility/modal-mixin',[
    'ko',
    'jquery',
    'UnifiedArts_Tsc/js/active',
    'jquery-ui-modules/widget'
], function (
    ko,
    $,
    tscActive
) {
    'use strict';

    return function (target) {
        var isCheckout;

        if (!tscActive.isActive()) {
            return $.mage.modal;
        }

        isCheckout = $('body').hasClass('checkout-index-index');

        $.widget('mage.modal', target, {
            options: {
                tscModalId: window.checkoutConfig && window.checkoutConfig.tsc && window.checkoutConfig.tsc.sidebar ?
                    window.checkoutConfig.tsc.sidebar.modalElementId : ''
            },

            /**
             * @inheritdoc
             * @override
             */
            _renderModal: function () {
                if (!this.tscIsCheckoutSidebar()) {
                    this._super();

                    return;
                }
                this._super();

                // Initially hide wrapper
                this.tscHideTemplateWrapper();
            },

            /**
             * Fix for IE 11 when overlay does not exist
             *
             * @override
             */
            _destroyOverlay: function () {
                if (this._getVisibleCount()) {
                    this.overlay.unbind().on('click', this.prevOverlayHandler);
                } else {
                    $(this.options.appendTo).removeClass(this.options.parentModalClass);

                    if (this.overlay) {
                        this.overlay.remove();
                    }
                    this.overlay = null;
                }
            },

            /**
             * Hide template wrapper
             */
            tscHideTemplateWrapper: function () {
                var el = this.tscGetTemplateWrapper();

                if (el.length > 0) {
                    el.hide();
                }
            },

            /**
             * Show template wrapper
             */
            tscShowTemplateWrapper: function () {
                var el = this.tscGetTemplateWrapper();

                if (el.length > 0) {
                    el.show();
                }
            },

            /**
             * Get template wrapper
             * @returns {Object}
             */
            tscGetTemplateWrapper: function () {
                return this.element.parents(this.tscGetTemplateWrapperSelector());
            },

            /**
             * Get template wrapper selector
             * @returns {String}
             */
            tscGetTemplateWrapperSelector: function () {
                var str = '.' + this.options.modalClass.replace(/ +(?= )/g, '');

                str = str.replace(/ /g, '.').replace(/#./g, '.');

                return str;
            },

            /**
             * Test if is active, checkout page and sidebar modal
             * @returns {Boolean}
             */
            tscIsCheckoutSidebar: function () {
                if (!isCheckout) {
                    return false;
                }

                return this.options.tscModalId !== '' && this.element.attr('id') === this.options.tscModalId;
            }
        });

        return $.mage.modal;
    };
});

