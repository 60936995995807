/**
 * Copyright ©  Unified Arts GmbH All rights reserved.
 * See LICENSE.txt for license details.
 *
 * {@link https://www.3step-checkout.de/}
 */

/* eslint-disable max-len */
define('UnifiedArts_Tsc/js/lib/knockout/template/inject-data',[], function () {
    'use strict';

    var autocorrectActive = window.checkoutConfig && window.checkoutConfig.tsc && window.checkoutConfig.tsc.address ?
            window.checkoutConfig.tsc.address.autocorrectActive : false,
        autocorrect = window.checkoutConfig && window.checkoutConfig.tsc && window.checkoutConfig.tsc.address ?
            window.checkoutConfig.tsc.address.autocorrect : 'off',
        // eslint-disable-next-line no-unused-vars
        autocapitalizeActive = window.checkoutConfig && window.checkoutConfig.tsc && window.checkoutConfig.tsc.address ?
            window.checkoutConfig.tsc.address.autocapitalizeActive : false,
        // eslint-disable-next-line no-unused-vars
        autocapitalize = window.checkoutConfig && window.checkoutConfig.tsc && window.checkoutConfig.tsc.address ?
            window.checkoutConfig.tsc.address.autocapitalize : 'off',
        autocompleteActive = window.checkoutConfig && window.checkoutConfig.tsc && window.checkoutConfig.tsc.address ?
            window.checkoutConfig.tsc.address.autocompleteActive : false,
        spellcheckActive = window.checkoutConfig && window.checkoutConfig.tsc && window.checkoutConfig.tsc.address ?
            window.checkoutConfig.tsc.address.spellcheckActive : false,
        spellcheck = window.checkoutConfig && window.checkoutConfig.tsc && window.checkoutConfig.tsc.address ?
            window.checkoutConfig.tsc.address.spellcheck : 'off',
        inputHtmlAttributes = '',
        inputBindExtra = '';

    if (autocorrectActive) {
        inputHtmlAttributes += ' autocorrect="' + autocorrect + '"';
    }

    /* Autocapitalize is bugged on Iphone. Feature moved in JS.
    if (autocapitalizeActive) {
        inputHtmlAttributes += ' autocapitalize="' + autocapitalize + '"';
    }*/

    if (spellcheckActive) {
        inputHtmlAttributes += ' spellcheck="' + spellcheck + '"';
    }

    if (inputHtmlAttributes !== '') {
        inputHtmlAttributes = inputHtmlAttributes.trim();
    }

    if (autocompleteActive) {
        inputBindExtra = 'autocomplete: autocomplete,';
    }

    /**
     * @see app/code/UnifiedArts/Tsc/view/frontend/web/js/lib/knockout/template/inject-content.js
     * Array item format:
     * - first line is a fixed key. Can be used in mixins to unset the item. No other meaning. It is not used by TSC.
     * - second line is the string to search in the HTML ko template. If it's found than the HTML is processed further.
     * - third line is the regular expression search string used as first parameter to JS replace function.
     * - fourth line is the regular expression replace string used as second parameter to JS replace function.
     */
    return {
        'replace': [

            /*
            // Example with regex: Replace CSS class 'opc-wrapper' with 'tsc-opc-wrapper'.
            [
                '3-vendor/magento/module-checkout/view/frontend/web/template/onepage.html',
                'opc-wrapper',
                /(?:class *= *['"]?)((?:[\w \-](?!\w+=|\/))+)['"]*!/gi,
                function (full, capture) {
                    return 'class="' +
                        capture.replace(/opc-wrapper(?: +|$)/g, 'tsc-opc-wrapper') +
                        '"';
                }
            ],*/

            /*
            // Example with regex: Add some HTML after a specific div (with class primary).
            [
                '10-1-vendor/magento/module-checkout/view/frontend/web/template/shipping.html',
                'id="checkout-step-shipping"',
                /(<div class="primary">[\S\s]*<\/div>)/mi,
                function (full, capture) {
                    var pos = full.indexOf('</div>'),
                        html = full,
                        buttonHtml;

                    buttonHtml = '\n<!-- ko if: tscCartShowLink-->\n' +
                        '<div class="secondary">\n' +
                        ' <a id="tsc-shipping-back" data-role="tsc-opc-back" ' +
                            ' data-bind="attr: {href: tscGetBackToCartUrl()}"' +
                        ' class="action prev-step back-to-cart secondary">\n' +
                        '    <span data-bind="text: tscBackToCartLabel"></span>\n' +
                        '  </a>\n' +
                        '</div>\n' +
                        '<!--/ko-->\n' +
                        '<div class="tsc-total-value" data-bind="html: tscGetQuickTotals()"></div>\n' +
                        '<!-- ko foreach: getRegion(\'tsc-highlight\') -->\n' +
                        '<!-- ko template: getTemplate() --><!-- /ko -->\n' +
                        '<!--/ko-->\n';

                    if (pos > 0) {
                        html = html.substring(0, pos + 6) + buttonHtml + html.substring(pos + 6);
                    }

                    return html;
                }
            ],*/

            [
                '15-vendor/magento/module-ui/view/frontend/web/templates/form/element/input.html',
                '<input class="input-text" type="text" data-bind="\n' +
                '    value: value,\n' +
                '    valueUpdate: \'keyup\',\n' +
                '    hasFocus: focused,',
                '<input class="input-text" type="text" data-bind="\n' +
                '    value: value,\n' +
                '    valueUpdate: \'keyup\',\n' +
                '    hasFocus: focused,\n' +
                '    attr: {\n',
                '<input class="input-text" type="text" ' + inputHtmlAttributes + ' data-bind="\n' +
                '    value: value,\n' +
                '    valueUpdate: \'keyup\',\n' +
                '    hasFocus: focused,\n' +
                '    afterRender: tscAfterRender,\n' +
                '    event: {\n' +
                '        focus: tscOnFocus,\n' +
                '        blur: tscOnBlur,\n' +
                '        change: tscOnChange\n' +
                '    },\n' +
                '    attr: {\n' +
                '        ' + inputBindExtra + '\n'
            ],

            [
                '24-vendor/magento/module-ui/view/frontend/web/templates/form/element/select.html',
                '<select class="select" data-bind="',
                'optionsAfterRender: function(option, item) {',
                'afterRender: tscUpdateSelect,\n' +
                '    optionsAfterRender: function(option, item) {'
            ],

            [
                '26-offline-payment-methods',
                'class="payment-method"',
                '<span data-bind="text: getTitle()"></span>',
                '<!-- ko if: (tscGetInfoTitle())--><div class="payment-tooltip"><i class="ua-icons icon-info"></i><p><strong data-bind="text: tscGetInfoTitle()"></strong><span data-bind="text: tscGetInfoDescription()"></span></p></div> <!-- /ko -->' +
                '<!-- ko if: (tscGetImageUrl())--><img data-bind="attr: {src: tscGetImageUrl(), alt: getTitle()}" /> <!-- /ko -->' +
                '<span class="title" data-bind="text: getTitle()"></span>' +
                '<!-- ko if: (tscGetDescription())--><small class="description" data-bind="text: tscGetDescription()"></small><!-- /ko -->'
            ],

            [
                '27-dummy-PP-external-code',
                '<div class="payment-method-note">',
                '<div class="payment-method-note">\n' +
                '                <!-- ko i18n: \'You will be redirected to the PayPal website.\' --><!-- /ko -->\n' +
                '            </div>',
                '<div class="payment-method-note">\n' +
                    '<span class="external-payment" data-bind="i18n: \'After clicking Complete order, you will be redirected to PayPal to complete your purchase securely.\'"></span>\n' +
                '</div>'
            ],

            [
                '28-remove-block-loaders',
                ', blockLoader: isLoading',
                ', blockLoader: isLoading',
                ''
            ],

            [
                '29-remove-block-loaders-declared-on-last-position',
                'blockLoader: isLoading',
                'blockLoader: isLoading',
                ''
            ],

            [
                '38 - all payment methods',
                '<p data-bind="html: getInstructions()"></p>',
                '<p data-bind="html: getInstructions()"></p>',
                '<!-- ko if: (getInstructions()) -->' +
                '<p data-bind="html: getInstructions()"></p>' +
                '<!-- /ko -->'
            ]
        ]
    };
});
/* eslint-enable max-len */
;
