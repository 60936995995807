/**
 * Copyright ©  Unified Arts GmbH All rights reserved.
 * See LICENSE.txt for license details.
 *
 * {@link https://www.3step-checkout.de/}
 */

define('UnifiedArts_EnhancedTaxDisplay/js/view/checkout/minicart/subtotal/percent',[
    'ko',
    'underscore',
    'uiComponent',
    'Magento_Customer/js/customer-data',
    'Magento_Catalog/js/price-utils'
], function (ko, _, Component, customerData) {
    'use strict';

    return Component.extend({
        defaults: {
            showTaxPercent: false,
            template: 'UnifiedArts_EnhancedTaxDisplay/checkout/minicart/subtotal/percent',
            separator: ',',
            percentSign: '%',
            isZeroTaxDisplayed: false
        },
        displaySubtotal: ko.observable(true),

        /**
         * @override
         */
        initialize: function () {
            this._super();
            this.cart = customerData.get('cart');
        },

        /**
         * Get tax percents
         * @returns {Array}
         */
        getPercents: function () {
            var self = this,
                percents = [];

            if (this.cart() && this.cart().uaTaxPercents) {
                _.each(this.cart().uaTaxPercents, function (percent) {
                    if (self.ifShowPercentValue(percent)) {
                        percents.push(percent);
                    }
                });
            }

            return percents;
        },

        /**
         * @param {Number} percent
         * @returns {Boolean}
         */
        ifShowPercentValue: function (percent) {
            if (percent === 0) {
                return this.isZeroTaxDisplayed;
            }

            return true;
        },

        /**
         * Get first tax percent
         * @returns {Number}
         */
        getPercentValue: function () {
            return this.getPercents().length > 0 ? _.first(this.getPercents()) : 0.0;
        },

        /**
         * Format percent number to locale
         * @param {Number} percent
         * @returns {String}
         */
        getFormattedValue: function (percent) {
            var formatter;

            if (this.getLocaleCode() !== false && typeof Intl !== 'undefined' && Intl && Intl.NumberFormat) {
                try {
                    formatter = new Intl.NumberFormat(this.getLocaleCode());

                    return formatter.format(percent);
                } catch (err) {
                    return percent.toString();
                }
            } else {
                return percent.toString();
            }
        },

        /**
         * Get current locale code
         * @returns {String|Boolean}
         */
        getLocaleCode: function () {
            if (this.cart() && this.cart().uaJsLocaleCode) {
                return this.cart().uaJsLocaleCode;
            }

            return false;
        },

        /**
         * Ger all percents for view
         * @returns {String}
         */
        getFormattedPercents: function () {
            var self = this,
                values = '',
                len = self.getPercents().length,
                iter = 0;

            if (len <= 0) {
                return '';
            }

            _.each(self.getPercents(), function (percent) {
                values += self.getFormattedValue(percent);

                if (iter++ < len) {
                    values += self.percentSign;

                    if (iter < len) {
                        values += self.separator;
                    }
                }
            });

            return values;
        },

        /**
         * Display tax percent(s)
         * @returns {Boolean}
         */
        displayPercent: function () {
            return this.showTaxPercent && this.getPercents().length > 0;
        }
    });
});

