/**
 * Copyright ©  Unified Arts GmbH All rights reserved.
 * See LICENSE.txt for license details.
 *
 * {@link https://www.3step-checkout.de/}
 */

/* eslint-disable max-len */
define('UnifiedArts_TscAmazonPay/js/lib/knockout/template/inject-data-mixin',[
    'UnifiedArts_Tsc/js/active'
], function (tscActive) {
    'use strict';

    var expressActive = !!(window.checkoutConfig && window.checkoutConfig.tscAmazonPay &&
            window.checkoutConfig.tscAmazonPay.express && window.checkoutConfig.tscAmazonPay.express.active),
        isActiveAmazonPayment = !!(window.checkoutConfig && window.checkoutConfig.tscAmazonPay &&
            window.checkoutConfig.tscAmazonPay.payment && window.checkoutConfig.tscAmazonPay.payment.active);

    return function (target) {
        if (!(tscActive.isActive() && expressActive && isActiveAmazonPayment)) {
            return target;
        }

        target.replace.push(
            [
                '11 - vendor/amzn/amazon-pay-magento-2-module/view/frontend/web/template/payment/amazon-payment-method.html',
                'id="amazon-payment"',
                '<img alt="Amazon Pay" data-bind="attr: { src: getLogoUrl() } "/>',
                '<!-- ko if: (tscGetInfoTitle())--><div class="payment-tooltip"><i class="ua-icons icon-info"></i><p><strong data-bind="text: tscGetInfoTitle()"></strong><span data-bind="text: tscGetInfoDescription()"></span></p></div> <!-- /ko -->' +
                '<!-- ko if: (getLogoUrl())--><img data-bind="attr: {src: getLogoUrl(), alt: getTitle()}" /> <!-- /ko -->' +
                '<span class="title" data-bind="text: getTitle()"></span>' +
                '<!-- ko if: (tscGetDescription())--><small class="description" data-bind="text: tscGetDescription()"></small><!-- /ko -->'
            ],
            [
                '11.2 - vendor/amzn/amazon-pay-magento-2-module/view/frontend/web/template/payment/amazon-payment-method.html',
                'id="amazon-payment"',
                '<img alt="Amazon Pay"data-bind="attr: { src: getLogoUrl() } ">',
                '<!-- ko if: (tscGetInfoTitle())--><div class="payment-tooltip"><i class="ua-icons icon-info"></i><p><strong data-bind="text: tscGetInfoTitle()"></strong><span data-bind="text: tscGetInfoDescription()"></span></p></div> <!-- /ko -->' +
                '<!-- ko if: (getLogoUrl())--><img data-bind="attr: {src: getLogoUrl(), alt: getTitle()}" /> <!-- /ko -->' +
                '<span class="title" data-bind="text: getTitle()"></span>' +
                '<!-- ko if: (tscGetDescription())--><small class="description" data-bind="text: tscGetDescription()"></small><!-- /ko -->'
            ],
            [
                '5.1-1 - vendor/amzn/amazon-pay-magento-2-module/view/frontend/web/template/payment/amazon-payment-method.html',
                'id="amazon-payment"',
                '<span data-bind="text: paymentDescriptor"></span>',
                ''
            ],
            [
                '5.1-2 - vendor/amzn/amazon-pay-magento-2-module/view/frontend/web/template/billing-address/details.html',
                'if="isAddressDetailsVisible() && currentBillingAddress()"',
                '<div if="isAddressDetailsVisible() && currentBillingAddress()">\n',
                '<div class="tsc-billing-address-details" if="isAddressDetailsVisible() && currentBillingAddress()">\n'
            ],
            [
                '5.1-3 - vendor/amzn/amazon-pay-magento-2-module/view/frontend/web/template/billing-address/details.html',
                'if="isAddressDetailsVisible() && currentBillingAddress()"',
                '<p data-bind="i18n: \'Billing address associated with this payment method:\'"></p>',
                '<div class="tsc-billing-address-details-title block header">\n' +
                '        <h4 data-bind="i18n: \'Invoice address\'"></h4>\n' +
                '        <p class="block-note" data-bind="i18n: \'Billing address associated with this payment method:\'"></p>\n' +
                '    </div>'
            ],
            [
                '5.1-4 - vendor/amzn/amazon-pay-magento-2-module/view/frontend/web/template/billing-address/details.html',
                'if="isAddressDetailsVisible() && currentBillingAddress()"',
                '</each>\n' +
                '    </p>',
                '</each>\n' +
                '\n' +
                '        <span class="selected-state"></span>\n' +
                '    </p>'
            ],
            [
                '3 - vendor/amzn/amazon-pay-magento-2-module/view/frontend/web/template/checkout-revert.html',
                'class="revert-checkout"',
                '<a href="javascript:;" data-bind="click: revertCheckout" class="revert-checkout">\n' +
                '        <!-- ko i18n: \'Return to standard checkout\' --><!--/ko-->\n' +
                '    </a>',
                '<p class="revert-checkout-holder">\n' +
                '<a href="javascript:;" data-bind="click: revertCheckout" class="revert-checkout">\n' +
                '        <!-- ko i18n: \'Return to standard checkout\' --><!--/ko-->\n' +
                '    </a>\n' +
                '</p>'
            ]
        );

        return target;
    };
});
/* eslint-enable max-len */
;
