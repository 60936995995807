define('Yireo_Webp2/js/add-webp-class-to-body',[
    'jquery',
    './has-webp'
], function($, hasWebP) {
    if (hasWebP()) {
        $('body').addClass('webp');
    } else {
        $('body').addClass('no-webp');
    }
});

